<template>
  <v-card
    v-if="product"
    class="pa-0"
    rounded
    outlined
    elevation="24"
  >
    <template #progress>
      <v-progress-linear
        color="dark"
        height="10"
        indeterminate
      />
    </template>
    <v-card-title class="pb-1">
      {{ product.productName }}
      <v-chip v-if="product.company" class="text-caption ml-1 white--text" small label>
        {{ product.company.companyName }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <div class="d-flex my-1 text-subtitle-1">
        <span v-if="product.valid === 'wait'">
          신청대기 <span class="text-caption blue-grey--text"> {{ parseDate(product.startDate) }}~</span>
        </span>
        <span v-else-if="product.valid === 'sale'" class="success--text">
          신청가능 <span class="text-caption blue-grey--text"> ~{{ parseDate(product.endDate) }}</span>
        </span>
        <span v-else-if="product.valid === 'end'" class="red--text">
          신청종료 <span class="text-caption blue-grey--text"> ~{{ parseDate(product.endDate) }}</span>
        </span>
        <v-spacer />
        <span>
          {{ product.productPrice ? '￦ ' + product.productPrice.toLocaleString() : '' }}
        </span>
      </div>
      <div class="d-flex my-1 text-caption blue-grey--text">
        <v-spacer />
        <template v-if="product.totalStock != null">
          남은수량 {{ product.totalStock.toLocaleString() }}개
        </template>
        <template v-else>
          품절
        </template>
      </div>
      <div class="d-flex my-1 text-caption blue-grey--text">
        <v-spacer />
        <span>
          배송비 {{ deliveryFee }}
        </span>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-dialog
        v-model="showArticles"
        persistent
      >
        <template #activator="{ attrs, on }">
          <v-btn
            class="px-5"
            width="100%"
            v-bind="attrs"
            v-on="on"
          >
            게시글 보기
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <h5>
              {{ product.productName }}의 게시글
            </h5>
            <v-dialog
              v-model="showHistory"
              max-width="100%"
              width="800"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  small
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  구매내역
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <h5>
                    {{ searchDate ? searchDate : '' }} 구매내역
                  </h5>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6">
                      <v-simple-table>
                        <template #default>
                          <thead>
                            <tr>
                              <th>구매 완료</th>
                            </tr>
                          </thead>
                          <tbody v-if="!histories || !histories.written || histories.written.length === 0">
                            <tr>
                              <td class="text-center">
                                목록이 없습니다.
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr v-for="(item, i) in histories.written" :key="`history-written-${i}`">
                              <td>
                                {{ item }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="6">
                      <v-simple-table>
                        <template #default>
                          <thead>
                            <tr>
                              <th>구매 안함</th>
                            </tr>
                          </thead>
                          <tbody v-if="!histories || !histories.noWritten || histories.noWritten.length === 0">
                            <tr>
                              <td class="text-center">
                                목록이 없습니다.
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr v-for="(item, i) in histories.noWritten" :key="`history-no-written-${i}`">
                              <td>
                                {{ item }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col cols="12" md="2" offset-md="7">
                <v-menu>
                  <template #activator="{ attrs, on }">
                    <v-text-field
                      v-model="searchDate"
                      label="Date"
                      dense
                      hide-details
                      prepend-inner-icon="mdi-calendar-check"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchDate"
                    :min="startDate ? startDate : ''"
                    :max="endDate ? endDate : ''"
                    locale="ko"
                    type="date"
                    @change="saveSearchDate"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="keyword"
                  append-outer-icon="mdi-magnify"
                  :append-icon="keyword ? 'mdi-close' : ''"
                  hide-details
                  dense
                  label="Keyword"
                  @keyup="enterSearch"
                  @click:append="initKeyword()"
                  @click:append-outer="getArticles(1)"
                />
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th v-for="(header, i) in headers" :id="`product-${product.idx}-header-${i}`" :key="`product-${product.idx}-header-${i}`" :class="header.clazz" :width="header.width">
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pagination.list && pagination.list.length > 0">
                  <tr v-for="item in pagination.list" :key="`product-${product.idx}-item-${item.idx}`">
                    <td class="text-center" @click="linkToArticle(item)">
                      {{ item.purchaseDate ? $parseDate(item.purchaseDate) : '-' }}
                    </td>
                    <td class="text-center text-truncate" @click="linkToArticle(item)">
                      {{ item.alias ? item.alias.username : item.users ? item.users.username : '-' }}
                    </td>
                    <td class="text-center" @click="linkToArticle(item)">
                      {{ item.alias ? item.alias.mobileIdx : item.users ? item.users.mobileIdx : '-' }}
                    </td>
                    <td class="text-center text-truncate" @click="linkToArticle(item)">
                      <v-icon v-if="item.photoReview" class="text-body-1">
                        mdi-camera
                      </v-icon>
                      [{{ item.productRegistration ? item.productRegistration.options.optionType.optionTypeName : '-' }}]&nbsp;
                      {{ item.productRegistration ? item.productRegistration.options.optionContent : '-' }}
                    </td>
                    <td class="text-center" @click="linkToArticle(item)">
                      {{ item.productRegistration ? item.productRegistration.options.optionPrice.toLocaleString() : '-' }}
                    </td>
                    <td class="text-center" @click="linkToArticle(item)">
                      <v-icon :color="getBuyProcess(item) ? 'green' : 'error'">
                        {{ getBuyProcess(item) ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                    <td class="text-center" @click="linkToArticle(item)">
                      <v-icon :color="getDeliveryProcess(item) ? 'green' : 'error'">
                        {{ getDeliveryProcess(item) ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                    <td class="text-center" @click="linkToArticle(item)">
                      <v-icon :color="getReviewProcess(item) ? 'green' : 'error'">
                        {{ getReviewProcess(item) ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td :colspan="headers.length" class="text-center">
                      해당 상품에 대한 게시글이 존재하지 않습니다.
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <LivePagination
              :pagination="pagination"
              :show-num="7"
              :query="$route.query"
              @pagination="getArticles"
              @last="getLastPage"
              @first="getFirstPage"
            />
            <v-spacer />
            <v-btn
              @click="closeArticles"
            >
              창닫기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
  import LivePagination from '@/components/core/LivePagination'
  export default {
    name: 'AdminProductCard',
    components: { LivePagination },
    props: {
      product: {},
      startDate: String,
      endDate: String,
    },
    data () {
      return {
        carouselHeight: 'initial',
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        pageNum: 1,
        showArticles: false,
        headers: [
          {
            text: '날짜',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '이름',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '번호',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '옵션',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '금액',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '구매',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '배송',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '리뷰',
            width: 'auto',
            clazz: 'text-center',
          },
        ],
        keyword: '',
        searchDate: '',
        showHistory: false,
        histories: {},
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
      deliveryFee () {
        const fee = this.product.deliveryFee
        const criteria = this.product.deliveryFeeCriteria
        let delivery
        if (!fee) {
          delivery = '무료배송'
        } else {
          delivery = fee.toLocaleString() + '원'
          if (criteria) {
            delivery += ' (' + criteria.toLocaleString() + '원 이상 구매 시 무료' + ')'
          }
        }
        return delivery
      },
    },
    watch: {
      showHistory () {
        if (this.showHistory) {
          this.getHistory()
        } else {
          this.histories = {}
        }
      },
      showArticles () {
        if (this.showArticles) {
          this.getArticles()
        } else {
          this.pagination = {
            list: [],
            pageNum: 1,
          }
          this.pageNum = 1
        }
      },
    },
    methods: {
      async getHistory () {
        const url = `/manage/product/${this.product.idx}/registration/users`
        const params = {}
        if (this.searchDate) {
          params.date = this.searchDate
        }
        await this.$axios.get(url, { params: params }).then(res => {
          if (res && res.data) {
            this.histories = res.data
          }
        })
      },
      saveSearchDate (date) {
        this.searchDate = date
      },
      linkToArticle (article) {
        const url = this.$router.resolve({ path: `/article/${article.idx}` })
        window.open(url.href)
      },
      getBuyProcess (article) {
        const type = article.articleType
        const status = article.status
        return !!((type === 'buy' && status === 'confirm') || (type === 'delivery' || type === 'review'))
      },
      getDeliveryProcess (article) {
        const type = article.articleType
        const status = article.status
        return !!((type === 'delivery' && status === 'confirm') || type === 'review')
      },
      getReviewProcess (article) {
        const type = article.articleType
        const status = article.status
        return !!(type === 'review' && status === 'confirm')
      },
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YY년 MM월 DD일')
        } else {
          return date
        }
      },
      initKeyword () {
        this.keyword = ''
        this.getArticles(1)
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.getArticles(1)
        }
      },
      async getArticles (pageNum) {
        const url = '/manage/article/product'
        const params = {
          pageNum: pageNum || this.pagination.pageNum,
          pageDataSize: this.pageDataSize,
          productIdx: this.product.idx,
          keyword: this.keyword,
          searchDate: this.searchDate,
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        await this.$axios.get(url, { params: params }).then(res => {
          if (res && res.data) {
            this.pagination = res.data
          }
        })
      },
      getLastPage () {
        const url = '/manage/article/product'
        const params = {
          pageNum: this.pagination.maxPageNum,
          pageDataSize: this.pageDataSize,
          productIdx: this.product.idx,
          keyword: this.keyword,
          searchDate: this.searchDate,
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getFirstPage () {
        const url = '/manage/article/product'
        const params = {
          pageNum: 1,
          pageDataSize: this.pageDataSize,
          productIdx: this.product.idx,
          keyword: this.keyword,
          searchDate: this.searchDate,
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      closeArticles () {
        this.showArticles = false
        this.searchDate = ''
      },
    },
  }
</script>

<style scoped lang="scss">
  .v-card::v-deep {
    .v-responsive.v-carousel__item {
      height: inherit !important;
    }
  }
</style>
