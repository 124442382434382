<template>
  <v-row>
    <v-col cols="12" md="3" offset-md="9">
      <v-select
        v-model="pointType"
        dense
        hide-details
        filled
        :items="pointTypes"
      />
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(col, i) in cols"
                :id="`point-cols-${i}`"
                :key="i"
                class="text-subtitle-1"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="!pagination || pagination.list.length === 0">
            <tr>
              <td :colspan="cols.length" class="text-body-1">
                <p class="text-center mt-5 mb-2">
                  포인트 변경 내역이 없습니다.
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(history, i) in pagination.list" :key="i">
              <td>
                <span class="text-body-1 text-truncate text-caption text-md-body-1">
                  {{ history.type }}
                </span>
              </td>
              <td>
                <span class="text-body-1 text-truncate text-caption text-md-body-1">
                  {{ history.amount.toLocaleString() }}
                </span>
              </td>
              <td>
                <span class="text-body-1 text-caption text-md-body-1">
                  {{ history.content ? history.content : '-' }}
                </span>
              </td>
              <td>
                <span class="text-body-1 text-caption text-md-body-1">
                  {{ history.article && history.article.company ? history.article.company.companyName : '-' }}
                </span>
              </td>
              <td>
                <span class="text-body-1 text-caption text-md-body-1">
                  {{ history.article ? history.article.title : '-' }}
                </span>
              </td>
              <td>
                <span class="text-body-1 text-truncate text-caption text-md-body-1">
                  {{ history.registerDate | moment('YYYY.MM.DD HH:mm:ss') }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-sheet class="px-3 mt-3">
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <LivePagination
              :pagination="pagination"
              :show-num="7"
              :query="$route.query"
              @pagination="getPagination"
              @last="getLastPage"
              @first="getFirstPage"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  import LivePagination from '@/components/core/LivePagination'
  export default {
    name: 'PointHistoryMain',
    components: { LivePagination },
    data () {
      return {
        cols: ['타입', '금액', '내용', '업체', '제목', '날짜'],
        pagination: {
          list: [
            {
              type: '활동',
              amount: -10000,
              content: '차감',
              date: '2022-03-16 10:10:55',
            },
          ],
          pageNum: 1,
        },
        pageDataSize: 10,
        pointType: '',
        pointTypes: [
          {
            text: '전체',
            value: '',
          },
          {
            text: '활동',
            value: 'activity',
          },
          {
            text: '적립',
            value: 'fee',
          },
          {
            text: '추천',
            value: 'recommend',
          },
          {
            text: '출금',
            value: 'withdraw',
          },
        ],
      }
    },
    watch: {
      pointType () {
        this.getPagination(1)
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      getPagination (pageNum) {
        const url = `/user/history/point/${pageNum || this.pagination.pageNum}`
        const params = {
          pointType: this.pointType,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getLastPage () {
        const url = `/user/history/point/${this.pagination.maxPageNum}`
        this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getFirstPage () {
        const url = '/user/history/point/1'
        this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
