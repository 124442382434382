<template>
  <v-menu
    ref="date-menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        small
        class="ml-2"
        v-bind="attrs"
        v-on="on"
      >
        월별조회
      </v-btn>
    </template>
    <v-date-picker
      v-model="date"
      locale="ko"
      type="month"
      :active-picker.sync="activePicker"
      min="1950-01-01"
      @change="saveDate"
    />
  </v-menu>
</template>

<script>
  export default {
    name: 'ArticleCalendarSelector',
    data () {
      return {
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        activePicker: null,
        dateMenu: false,
      }
    },
    methods: {
      saveDate (date) {
        const startYear = date.split('-')[0]
        const endYear = date.split('-')[0]
        const startMonth = date.split('-')[1] - 1
        const endMonth = date.split('-')[1] - 1
        const startDate = new Date(startYear, startMonth, 1)
        startDate.setHours(0)
        startDate.setMinutes(0)
        startDate.setSeconds(0)
        startDate.setMilliseconds(0)
        const endDate = new Date(endYear, endMonth + 1, 0)
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)
        endDate.setMilliseconds(999)
        const url = `/board/1?sdt=${startDate.getTime()}&edt=${endDate.getTime()}`
        this.$router.push(url)
      },
    },
  }
</script>

<style scoped>

</style>
