<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table style="overflow-x: auto">
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(col, i) in cols"
                :id="`point-cols-${i}`"
                :key="i"
                class="text-subtitle-1 text-truncate"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="!pagination || pagination.list.length === 0">
            <tr>
              <td :colspan="cols.length" class="text-body-1">
                <p class="text-center mt-5 mb-2">
                  작성한 게시글이 없습니다.
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(article, i) in pagination.list" :key="i">
              <td @click="clickArticle(article)">
                <span class="text-body-2 text-truncate">
                  {{ article.idx }}
                </span>
              </td>
              <td @click="clickArticle(article)">
                <span class="text-body-2 text-truncate">
                  {{ article.company ? article.company.companyName : '-' }}
                </span>
              </td>
              <td @click="clickArticle(article)">
                <span class="text-body-2 text-truncate">
                  <template v-if="article.productRegistration && article.productRegistration.product && article.productRegistration.product.month">
                    {{ article.type === '배송완료' ? '구매확정' : article.type }}
                  </template>
                  <template v-else>
                    {{ article.type }}
                  </template>
                </span>
              </td>
              <td @click="clickArticle(article)">
                <span class="text-body-2 text-truncate">
                  {{ article.status === 'pending' ? '대기' : article.status === 'confirm' ? '승인' : article.status === 'reject' ? '반려' : '' }}
                </span>
              </td>
              <td @click="clickArticle(article)">
                <v-dialog
                  v-model="article.showDialog"
                  width="800px"
                  max-width="100%"
                  :fullscreen="mobile"
                  :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                >
                  <template #activator="{ attrs, on }">
                    <span
                      :id="'article-' + article.idx"
                      class="text-body-2 text-truncate"
                      v-bind="attrs"
                      v-on="on"
                      @click="selectedArticle = article"
                    >
                      {{ article.title }}
                    </span>
                  </template>
                  <v-card>
                    <v-card-text>
                      <BoardViewContent
                        :article="article"
                        :popup="true"
                        :product="article.productRegistration && article.productRegistration.product ? article.productRegistration.product : {}"
                        :options="article.productRegistration && article.productRegistration.options ? article.productRegistration.options : {}"
                        @refresh="changeValue"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn large width="100%" @click="article.showDialog = false">
                        창 닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
              <td @click="clickArticle(article)">
                <span class="text-body-2 text-truncate">
                  <span v-if="new Date().getTime() - article.registerDate < 1000 * 60 * 60 * 7">
                    {{ article.registerDate | moment('from', 'now') }}
                  </span>
                  <span v-else>
                    {{ article.registerDate | moment('YYYY.MM.DD. HH:mm:ss') }}
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-sheet class="px-3 mt-3">
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <LivePagination
              :pagination="pagination"
              :show-num="7"
              :query="$route.query"
              @pagination="getPagination"
              @last="getLastPage"
              @first="getFirstPage"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  import LivePagination from '@/components/core/LivePagination'
  import BoardViewContent from '@/views/board/view/Content'
  import $ from 'jquery'
  export default {
    name: 'ArticleMain',
    components: { BoardViewContent, LivePagination },
    data () {
      return {
        cols: ['글번호', '업체', '게시판', '상태', '제목', '날짜'],
        pagination: {
          list: [],
          pageNum: 1,
        },
        pageDataSize: 10,
        selectedArticle: null,
        updateIndex: null,
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      getPagination (pageNum) {
        const url = '/article/articles'
        const params = {
          pageNum: pageNum || this.pagination.pageNum,
          pageDataSize: this.pageDataSize,
          all: true,
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              if (this.updateIndex) {
                for (const article of res.data.list) {
                  if (article.idx === this.updateIndex) {
                    article.showDialog = true
                    this.updateIndex = null
                    break
                  }
                }
              }
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      changeValue (article) {
        this.updateIndex = article.idx
        this.getPagination()
      },
      getLastPage () {
        const url = `/article/articles/${this.$store.state.user.id}/${this.pagination.maxPageNum}`
        this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      getFirstPage () {
        const url = `/article/articles/${this.$store.state.user.id}/1`
        this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
      },
      clickArticle (article) {
        $('#article-' + article.idx).click()
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
