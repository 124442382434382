<template>
  <v-container>
    <template v-if="!$store.state.user.user.admin">
      <v-subheader>
        <v-icon class="mr-2">
          mdi-account
        </v-icon>
        사용자 정보
        <v-spacer />
        <v-btn
          color="grey"
          text
          small
          @click="$router.push('/my-page')"
        >
          상세정보 >
        </v-btn>
      </v-subheader>
      <v-divider class="mb-5" />
      <v-row>
        <v-col cols="12" md="3">
          <v-sheet
            class="d-flex justify-center align-center rounded-5 pa-5"
            elevation="3"
            height="100%"
          >
            <h1>
              {{ user.username }}
            </h1>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
          <v-sheet
            class="d-flex flex-wrap justify-center align-center rounded-5 pa-5"
            elevation="3"
            height="100%"
          >
            <h2 class="text-center mb-0 w-100">
              활동포인트
            </h2>
            <h2 class="text-center mb-0 w-100 font-weight-light">
              {{ user.activityPoints.toLocaleString() }}
            </h2>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
          <v-sheet
            class="d-flex flex-wrap justify-center align-center rounded-5 pa-5"
            elevation="3"
            height="100%"
          >
            <h2 class="text-center mb-0 w-100">
              적립포인트
            </h2>
            <h2 class="text-center mb-0 w-100 font-weight-light">
              {{ user.feePoints.toLocaleString() }}
            </h2>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
          <v-sheet
            class="d-flex flex-wrap justify-center align-center rounded-5 pa-5"
            elevation="3"
            height="100%"
          >
            <h2 class="text-center mb-0 w-100">
              추천인 포인트
            </h2>
            <h2 class="text-center mb-0 w-100 font-weight-light">
              {{ user.recommendPoints.toLocaleString() }}
            </h2>
          </v-sheet>
        </v-col>
      </v-row>
      <v-subheader class="mt-5">
        <v-icon class="mr-2">
          mdi-account-group
        </v-icon>
        추가 사용자
      </v-subheader>
      <v-divider class="mb-5" />
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template #default>
              <tbody v-if="user.aliases && user.aliases.length > 0">
                <tr v-for="alias in user.aliases" :key="alias.idx">
                  <td class="text-center w-15 text-truncate">
                    {{ alias.id }}
                  </td>
                  <td class="text-center w-15 text-truncate">
                    {{ alias.username }}
                  </td>
                  <td class="text-center w-70">
                    <span class="text-caption text-truncate text-md-body-2">
                      {{ getAddress(alias.userAddress, alias.userAddressDetail) }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center">
                    추가사용자가 존재하지 않습니다.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-subheader class="mt-5">
        <v-icon class="mr-2">
          mdi-clipboard-account-outline
        </v-icon>
        내가 쓴 글
        <ArticleCalendarSelector />
        <v-dialog
          v-model="isShowWrite"
          :width="mobile ? '100%' : '600'"
          :fullscreen="mobile"
          :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
        >
          <template #activator="{ attrs, on }">
            <v-btn
              small
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              글쓰기
            </v-btn>
          </template>
          <BoardWrite :companies="companies" @close-dialog="closeDialog" />
        </v-dialog>
      </v-subheader>
      <v-divider class="mb-5" />
      <v-row>
        <v-col cols="12">
          <ArticleMain ref="articleMain" />
        </v-col>
      </v-row>
      <v-subheader class="mt-5">
        <v-icon class="mr-2">
          mdi-file-powerpoint-box-outline
        </v-icon>
        포인트내역
      </v-subheader>
      <v-divider class="mb-5" />
      <v-row>
        <v-col cols="12">
          <PointHistoryMain />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="$store.state.user.user.admin">
      <v-subheader>
        <v-icon class="mr-2">
          mdi-new-box
        </v-icon>
        신규상품
      </v-subheader>
      <v-subheader class="mb-2">
        <v-row>
          <v-col cols="2">
            <v-menu>
              <template #activator="{ attrs, on }">
                <v-text-field
                  v-model="startDate"
                  label="Start"
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar-check"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                locale="ko"
                type="date"
                @change="saveStartDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-menu>
              <template #activator="{ attrs, on }">
                <v-text-field
                  v-model="endDate"
                  label="End"
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar-check"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                locale="ko"
                type="date"
                @change="saveEndDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="keyword"
              label="Keyword"
              dense
              hide-details
              append-icon="mdi-magnify"
              @click:append="getPagination(1)"
              @keyup="enterSearch"
            />
          </v-col>
          <v-col cols="4" class="text-right">
            <AllProductRegistrations />
          </v-col>
        </v-row>
      </v-subheader>
      <v-row>
        <v-col v-for="product in pagination.list" :key="`product-${product.idx}`" cols="6" md="4">
          <AdminProductCard :product="product" :start-date="startDate" :end-date="endDate" />
        </v-col>
      </v-row>
      <v-sheet :style="{ borderRadius: '.7rem', background: 'transparent' }" class="pt-3 text-center">
        <LivePagination
          :pagination="pagination"
          :show-num="7"
          :query="$route.query"
          background="transparent"
          @pagination="getPagination"
          @last="getLastPage"
          @first="getFirstPage"
        />
      </v-sheet>
    </template>
  </v-container>
</template>

<script>
  import ArticleMain from './ArticleMain'
  import BoardWrite from '../board/Register'
  import ArticleCalendarSelector from '../../components/core/ArticleCalendarSelector'
  import PointHistoryMain from '@/views/home/PointHistoryMain'
  import AdminProductCard from '@/components/product/AdminProductCard'
  import LivePagination from '@/components/core/LivePagination'
  import AllProductRegistrations from '@/components/product/AllProductRegistrations'
  export default {
    name: 'Home',
    components: { AllProductRegistrations, LivePagination, AdminProductCard, PointHistoryMain, ArticleCalendarSelector, BoardWrite, ArticleMain },
    data () {
      return {
        open: [],
        isShowWrite: false,
        isShowCalendar: false,
        companies: [],
        pagination: {
          list: [],
          pageNum: this.pageNum ? this.pageNum : 1,
        },
        pageDataSize: 6,
        pageNum: 1,
        startDate: '',
        endDate: '',
        keyword: '',
      }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      },
      mobile () {
        return this.$isMobile
      },
    },
    watch: {
      startDate () {
        if (this.endDate) {
          this.pageNum = 1
        }
        this.getPagination()
      },
      endDate () {
        if (this.startDate) {
          this.pageNum = 1
        }
        this.getPagination()
      },
    },
    beforeMount () {
      if (this.$store.state.user.user.admin) {
        this.getPagination()
      } else {
        this.getCompanies()
      }
    },
    methods: {
      async getPagination (payload) {
        const url = '/product/products'
        const params = {
          pageNum: payload || this.pageNum,
          pageDataSize: this.pageDataSize,
          startDate: this.startDate,
          endDate: this.endDate,
          keyword: this.keyword,
        }
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getLastPage () {
        const url = '/product/products'
        const params = {
          pageNum: this.pagination.maxPageNum,
          pageDataSize: this.pageDataSize,
          startDate: this.startDate,
          endDate: this.endDate,
          keyword: this.keyword,
        }
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getFirstPage () {
        const url = '/product/products'
        const params = {
          pageNum: 1,
          pageDataSize: this.pageDataSize,
          startDate: this.startDate,
          endDate: this.endDate,
          keyword: this.keyword,
        }
        await this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getMobile (reg) {
        let mobile = null
        if (reg.alias) {
          mobile = reg.alias.mobile && reg.alias.mobile.length > 4 ? reg.alias.mobile.substring(reg.alias.mobile.length - 4) : '-'
        } else {
          mobile = reg.users.mobile && reg.users.mobile.length > 4 ? reg.users.mobile.substring(reg.users.mobile.length - 4) : '-'
        }
        return mobile
      },
      closeDialog () {
        this.isShowWrite = false
        this.$refs.articleMain.getPagination()
      },
      async getCompanies () {
        const url = '/company/company/items'
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.companies = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      getAddress (address, detail) {
        let fullAddress = address + ' ' + detail
        if (this.mobile && fullAddress.length > 16) {
          fullAddress = fullAddress.substring(0, 16) + '..'
        }
        return fullAddress
      },
      saveStartDate (date) {
        this.startDate = date
      },
      saveEndDate (date) {
        this.endDate = date
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.getPagination(1)
        }
      },
    },
  }
</script>
