<template>
  <v-dialog
    v-model="showArticles"
    persistent
  >
    <template #activator="{ attrs, on }">
      <v-btn
        class="px-5"
        v-bind="attrs"
        v-on="on"
      >
        전체 구매내역
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h5>
          검색된 구매내역
        </h5>
      </v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col cols="6" md="4">
            <v-menu>
              <template #activator="{ attrs, on }">
                <v-text-field
                  v-model="startDate"
                  label="Start"
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar-check"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                locale="ko"
                type="date"
                @change="saveStartDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="6" md="4">
            <v-menu>
              <template #activator="{ attrs, on }">
                <v-text-field
                  v-model="endDate"
                  label="End"
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar-check"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                locale="ko"
                type="date"
                @change="saveEndDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="keyword"
              append-outer-icon="mdi-magnify"
              :append-icon="keyword ? 'mdi-close' : ''"
              hide-details
              dense
              label="Keyword"
              @keyup="enterSearch"
              @click:append="initKeyword()"
              @click:append-outer="search"
            />
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <h4>구매자</h4>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th v-for="(header, i) in headers" :key="`product-all-header-${i}`" :class="header.clazz" :width="header.width">
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="buyPagination.list && buyPagination.list.length > 0">
                  <template v-for="item in buyPagination.list">
                    <tr v-if="item.article" :key="`product-buy-item-${item.idx}`">
                      <td class="text-center text-truncate">
                        {{ item.alias ? item.alias.username : item.users ? item.users.username : '-' }}
                      </td>
                      <td class="text-center text-truncate">
                        {{ item.purchaseDate ? $parseDate(item.purchaseDate) : item.purchaseDate }}
                      </td>
                      <td class="text-center">
                        {{ item.alias ? item.alias.mobile : item.users ? item.users.mobile : '-' }}
                      </td>
                      <td class="text-center text-truncate">
                        {{ item.product ? item.product.productName : '-' }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td :colspan="headers.length" class="text-center">
                      해당 상품에 대한 게시글이 존재하지 않습니다.
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-sheet>
              <LivePagination
                :pagination="buyPagination"
                :show-num="7"
                :query="$route.query"
                @pagination="getBuyRegistration"
                @last="getBuyLastPage"
                @first="getBuyFirstPage"
              />
            </v-sheet>
          </v-col>
          <v-divider vertical />
          <v-col cols="12" md="6">
            <h4>미구매자</h4>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th v-for="(header, i) in headers" :key="`product-all-header-${i}`" :class="header.clazz" :width="header.width">
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="nonBuyPagination.list && nonBuyPagination.list.length > 0">
                  <template v-for="item in nonBuyPagination.list">
                    <tr v-if="!item.article" :key="`product-non-buy-item-${item.idx}`">
                      <td class="text-center text-truncate">
                        {{ item.alias ? item.alias.username : item.users ? item.users.username : '-' }}
                      </td>
                      <td class="text-center text-truncate">
                        {{ item.purchaseDate ? $parseDate(item.purchaseDate) : item.purchaseDate }}
                      </td>
                      <td class="text-center">
                        {{ item.alias ? item.alias.mobile : item.users ? item.users.mobile : '-' }}
                      </td>
                      <td class="text-center text-truncate">
                        {{ item.product ? item.product.productName : '-' }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td :colspan="headers.length" class="text-center">
                      해당 상품에 대한 게시글이 존재하지 않습니다.
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-sheet>
              <LivePagination
                :pagination="nonBuyPagination"
                :show-num="7"
                :query="$route.query"
                @pagination="getNonBuyRegistration"
                @last="getNonBuyLastPage"
                @first="getNonBuyFirstPage"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="closeArticles"
        >
          창닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import LivePagination from '@/components/core/LivePagination'
  export default {
    name: 'AllProductRegistrations',
    components: { LivePagination },
    data () {
      return {
        buyPagination: {
          list: [],
          pageNum: this.buyPageNum ? this.buyPageNum : 1,
        },
        nonBuyPagination: {
          list: [],
          pageNum: this.nonBuyPageNum ? this.nonBuyPageNum : 1,
        },
        buyPageNum: 1,
        nonBuyPageNum: 1,
        headers: [
          {
            text: '이름',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '구매일',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '연락처',
            width: 'auto',
            clazz: 'text-center',
          },
          {
            text: '키워드',
            width: 'auto',
            clazz: 'text-center',
          },
        ],
        showArticles: false,
        keyword: '',
        searchDate: '',
        startDate: '',
        endDate: '',
      }
    },
    watch: {
      showArticles () {
        if (this.showArticles) {
          this.getBuyRegistration()
          this.getNonBuyRegistration()
        } else {
          this.pagination = {
            list: [],
            pageNum: 1,
          }
          this.buyPageNum = 1
          this.nonBuyPageNum = 1
        }
      },
    },
    methods: {
      saveStartDate (date) {
        this.startDate = date
      },
      saveEndDate (date) {
        this.endDate = date
      },
      initKeyword () {
        this.keyword = ''
        this.startDate = ''
        this.endDate = ''
        this.search(1)
      },
      search () {
        this.getBuyRegistration(1)
        this.getNonBuyRegistration(1)
      },
      enterSearch () {
        if (event.keyCode === 13) {
          this.search()
        }
      },
      async getBuyRegistration (pageNum) {
        const url = '/manage/product/registration/users'
        const params = {
          pageNum: pageNum || this.buyPagination.pageNum,
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          type: 'buy',
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        await this.$axios.get(url, { params: params }).then(res => {
          if (res && res.data) {
            this.buyPagination = res.data
          }
        })
      },
      async getNonBuyRegistration (pageNum) {
        const url = '/manage/product/registration/users'
        const params = {
          pageNum: pageNum || this.nonBuyPagination.pageNum,
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          type: 'nonBuy',
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        await this.$axios.get(url, { params: params }).then(res => {
          if (res && res.data) {
            this.nonBuyPagination = res.data
          }
        })
      },
      getBuyLastPage () {
        const url = '/manage/product/registration/users'
        const params = {
          pageNum: this.buyPagination.maxPageNum,
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          type: 'buy',
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.buyPagination = res.data
            }
          })
      },
      getBuyFirstPage () {
        const url = '/manage/product/registration/users'
        const params = {
          pageNum: 1,
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          type: 'buy',
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.buyPagination = res.data
            }
          })
      },
      getNonBuyLastPage () {
        const url = '/manage/product/registration/users'
        const params = {
          pageNum: this.nonBuyPagination.maxPageNum,
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          type: 'nonBuy',
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.nonBuyPagination = res.data
            }
          })
      },
      getNonBuyFirstPage () {
        const url = '/manage/product/registration/users'
        const params = {
          pageNum: 1,
          pageDataSize: this.pageDataSize,
          keyword: this.keyword,
          type: 'nonBuy',
        }
        if (this.startDate && this.endDate) {
          params.sdt = this.startDate
          params.edt = this.endDate
        }
        this.$axios.get(url, { params: params })
          .then(res => {
            if (res && res.data) {
              this.nonBuyPagination = res.data
            }
          })
      },
      closeArticles () {
        this.showArticles = false
        this.startDate = ''
        this.endDate = ''
      },
    },
  }
</script>

<style scoped>

</style>
